
import { Vue, Component } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
@Component
export default class extends Vue {
  $refs!: {
    defaultActions: HTMLFormElement;
  };
  /* Properties */
  /* Store Actions */
  @Getter('displayName', { namespace: 'profile' })
  displayName!: string;
  @Action('logout', { namespace: 'profile' }) logout: any;
  /* Watchers */
  /* Data */
  /* Utility Functions */
  goTo(name: string) {
    this.$router.push(name);
  }
  /* Loaders */
  /* Mounted */
  mounted() {
    if (this.$route.fullPath.includes('redirectFromTransparency')) {
      this.logout();
      return;
    }
    if (this.displayName) this.$router.push('/items');
  }
  /* Created */
  /* Emmited Functions */
}
